export default {
  // api请求前缀
  // #ifdef H5
  webUrl: "/api",
  // #endif
  // #ifndef H5
  // #endif
  uploadImageUrl: "https://qinghuiche.oss-cn-beijing.aliyuncs.com/",
  OSSAccessKeyId: "LTAI5t5iCMvk22nJ9SJ2QWkT",
  timeout: "360000",
  AccessKeySecret: "wEhkLe8IX56xKOofjYRVkXqFdIjE0E",
};
