// const env = require('config.js'); //配置文件，在这文件里配置你的OSS keyId和KeySecret,timeout:87600;

// const base64 = require('base64.js'); //Base64,hmac,sha1,crypto相关算法
// require('hmac.js');
// require('sha1.js');
// const Crypto = require('crypto.js');
import env from "@/utils/ali-oss/config.js";
import base64 from "@/utils/ali-oss/base64.js";
import hmac from "@/utils/ali-oss/hmac.js";
import sha1 from "@/utils/ali-oss/sha1.js";
import Crypto from "@/utils/ali-oss/crypto.js";
import { message } from "ant-design-vue";
import request from "@/utils/request.js";
import Md5 from "js-md5";

const getPolicyBase64 = function () {
  let date = new Date();
  date.setHours(date.getHours() + env.timeout);
  let srcT = date.toISOString();
  const policyText = {
    expiration: srcT, //设置该Policy的失效时间，超过这个失效时间之后，就没有办法通过这个policy上传文件了
    conditions: [
      ["content-length-range", 0, 500 * 1024 * 1024], // 设置上传文件的大小限制,5mb
    ],
  };

  const policyBase64 = base64.encode(JSON.stringify(policyText));
  return policyBase64;
};

const getSignature = function (policyBase64) {
  const accesskey = env.AccessKeySecret;

  const bytes = Crypto.HMAC(Crypto.SHA1, policyBase64, accesskey, {
    asBytes: true,
  });
  const signature = Crypto.Crypto.util.bytesToBase64(bytes);

  return signature;
};

// 获取随机字符串
function random_string(len) {
  len = len || 32;
  let chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
  let maxPos = chars.length;
  let pwd = "";
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
}
// 获取图片名称和后缀
function getSuffix(filename) {
  let name = "";
  let pos = filename.lastIndexOf(".");
  let suffix = "";
  if (pos != -1) {
    suffix = filename.substring(pos);
    name = filename.substring(0, pos);
  }
  return { suffix, name };
}
// 生成图片名
function picName(fileName) {
  let suffix = getSuffix(fileName).suffix;
  let name = getSuffix(fileName).name;
  let _fileName = "";
  let time = new Date().getTime();
  _fileName = Md5(name + time + random_string(10)) + suffix;
  return _fileName;
}

/*
 *上传文件到阿里云oss
 *@param - filePath :图片的本地资源路径
 *@param - dir:表示要传到哪个目录下
 *@param - successc:成功回调
 *@param - failc:失败回调
 */
export const uploadFile = function (
  filePath,
  dir,
  type,
  fileName,
  successc,
  failc
) {
  if (!filePath || filePath.length < 9) {
    if (type == "image") {
      message.error("图片错误，请重试");
    } else if (type == "video") {
      message.error("视频错误，请重试");
    } else {
      message.error("文件错误，请重试");
    }
    return;
  }
  //图片名字 可以自行定义，     这里是采用当前的时间戳 + 150内的随机数来给图片命名的
  const aliyunFileKey = dir + picName(fileName);
  const aliyunServerURL = env.uploadImageUrl; //OSS地址，需要https
  const accessid = env.OSSAccessKeyId;
  const policyBase64 = getPolicyBase64();
  const signature = getSignature(policyBase64); //获取签名
  // console.log(filePath);
  const formData = new FormData();
  formData.append("key", aliyunFileKey);
  formData.append("policy", policyBase64);
  formData.append("OSSAccessKeyId", accessid);
  formData.append("signature", signature);
  formData.append("name", "file");
  formData.append("success_action_status", 200);
  formData.append("file", filePath);
  request
    .post(aliyunServerURL, formData)
    .then(() => {
      // console.log("res", res);
      successc(aliyunServerURL + aliyunFileKey);
    })
    .catch((res) => {
      console.log(res);
      JSON.stringify(res);
      failc({ msg: "上传照片失败" });
    });
};
