<template>
  <div class="upload-image">
    <a-upload
      :before-upload="beforeUpload"
      :customRequest="upLoadImage"
      list-type="picture-card"
      v-model:file-list="fileList"
      :max-count="fileListCount"
      @remove="remove"
      @preview="handlePreview"
      :disabled="disabled"
    >
      <div v-if="fileListCount < imageCount">
        <plus-outlined />
        <div class="ant-upload-text">上传</div>
      </div>
      <div class="color-gray" v-if="tip">{{ tip }}</div>
    </a-upload>
    <a-modal
      class="upload-image"
      :width="'100vw'"
      :height="'100vh'"
      :style="{ top: 0, backgroundColor: 'transparent' }"
      :visible="previewVisible"
      :bodyStyle="{
        padding: 0,
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
      }"
      :footer="null"
      :closable="false"
      @cancel="handleCancel"
    >
      <div class="upload-image-tool">
        <span title="放大" @click="addMultiple">
          <zoom-in-outlined :style="{ color: '#fff', fontSize: '25px' }" />
        </span>
        <span title="缩小" @click="reduceMultiple">
          <zoom-out-outlined :style="{ color: '#fff', fontSize: '25px' }" />
        </span>
        <span title="旋转" @click="rotateImage">
          <redo-outlined :style="{ color: '#fff', fontSize: '25px' }" />
        </span>
        <span title="关闭" @click="handleCancel">
          <close-outlined :style="{ color: '#fff', fontSize: '25px' }" />
        </span>
      </div>
      <div :style="{ transform: imageTransform3D }" class="image-box">
        <img
          alt="image"
          :src="previewImage"
          :style="{
            transform: imageTransform,
            cursor: cursor,
            userSelect: 'none',
          }"
          @mousedown="mousedown(site, $event)"
          @mouseup="mouseup(site, $event)"
        />
      </div>
    </a-modal>
  </div>
</template>
<script>
import {
  computed,
  defineComponent,
  nextTick,
  reactive,
  ref,
  toRaw,
  toRefs,
  watch,
} from "vue";
import { message, Upload } from "ant-design-vue";
import {
  UploadOutlined,
  CloseOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  RedoOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";

import { uploadFile } from "@/utils/ali-oss/upload.js";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default defineComponent({
  name: "uploadImageTool",
  props: {
    imageList: {
      default: null,
    },
    imageCount: {
      default: 100,
    },
    tip: {
      default: null,
    },
    dir: {
      default: "",
    },
    couldRename: {
      default: false,
    },
    disabled:{
      default:false
    }
  },
  emits: ["update:imageList", "change"],
  setup(props, context) {
    const uploadBtn = ref(null);
    const pageData = reactive({
      fileList: [],
      fileListDefault: [],
      fileListCount: 0,
    });
    // 检验类型
    const beforeUpload = (file) => {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";

      if (!isJpgOrPng) {
        message.error("请上传正确的图片格式");
      }

      return isJpgOrPng || Upload.LIST_IGNORE;
    };

    // 转换格式 Array => String
    const getImageString = (res) => {
      let arrnew = res.map((item) => {
        return Object.assign([], [item.url]);
      });
      context.emit("update:imageList", arrnew.join(","));
      context.emit("change", true);
    };
    // 上传图片
    const uploadFileFun = (_img, _name) => {
      return new Promise((resolve, reject) => {
        uploadFile(
          _img,
          "renli-sys/",
          "image",
          _name,
          function (res) {
            resolve(res);
          },
          function (res) {
            reject(res);
          }
        );
      });
    };
    // 上传图片
    const upLoadImage = (res) => {
      uploadFileFun(res.file, res.file.name)
        .then((resulte) => {
          let a = {};
          a.name = res.file.name;
          //   a.uid = res.file.uid;
          a.status = "done";
          a.url = resulte;
          pageData.fileListDefault.push(a);
          getImageString(pageData.fileListDefault);
          res.onSuccess(resulte);
        })
        .catch((resulte) => {
          if (resulte.name == "FileAlreadyExistsError") {
            message.error("已存在相同命名图片，请将图片重命名再次上传");
          } else {
            message.error("网络错误，请稍后再试11");
          }
          res.onError(resulte);
        });
    };
    // 删除
    const remove = (file) => {
      // console.log('file',file);
      const index = pageData.fileListDefault.indexOf(file);
      pageData.fileListDefault.splice(index, 1);
      // console.log('pageData.fileListDefault',pageData.fileListDefault);
      getImageString(pageData.fileListDefault);
    };

    const getImageName = (res) => {
      let arr = res.split("/");
      return arr[arr.length - 1];
    };
    // 转换格式 String => Array
    const getList = (res) => {
      let b = toRaw(res).split(",");
      let list = [];
      b.forEach((e, i) => {
        let a = {};
        // a.uid = `-${i + 1}`;
        a.name = getImageName(e);
        a.status = "done";
        a.url = e;
        list.push(a);
      });
      pageData.fileListDefault = list;
    };

    watch(
      () => props.imageList,
      (val) => {
        if (val) {
          getList(val);
        } else {
          pageData.fileListDefault = [];
          pageData.fileList = [];
        }
      },
      { immediate: true }
    );
    watch(
      () => pageData.fileListDefault,
      (val) => {
        pageData.fileList = val;
        pageData.fileListCount = val.length;
      },
      { immediate: true }
    );

    const imageSize = ref(1); // 放大
    const imageRotate = ref(0); // 旋转
    const positionX = ref(0); // 移动
    const positionY = ref(0);
    const positionZ = ref(0);
    const cursor = ref("grab");
    // 预览图片
    const previewVisible = ref(false);
    const previewImage = ref("");
    // 关闭预览
    const handleCancel = () => {
      previewVisible.value = false;
    };
    // 预览图片
    const handlePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      previewImage.value = file.url || file.preview;
      previewVisible.value = true;
    };

    // 放大图片
    const addMultiple = () => {
      imageSize.value = imageSize.value + 0.5;
    };
    // 缩小图片
    const reduceMultiple = () => {
      if (imageSize.value > 1) {
        imageSize.value = imageSize.value - 0.5;
      } else if (imageSize.value - 0.25 > 0) {
        imageSize.value = imageSize.value - 0.25;
      }
    };
    // 旋转图片
    const rotateImage = () => {
      imageRotate.value = imageRotate.value + 90;
    };
    // 拖拽图片
    const mousedown = (site, _event) => {
      cursor.value = "grabbing";
      var event = _event || window.event;
      // var _target = event.target;
      var startx = event.clientX;
      var starty = event.clientY;
      // var sb_bkx = startx - event.target.offsetLeft;
      // var sb_bky = starty - event.target.offsetTop;
      var ww = document.documentElement.clientWidth;
      var wh = window.innerHeight;
      if (event.preventDefault) {
        event.preventDefault();
      } else {
        event.returnValue = false;
      }
      document.onmousemove = function (ev) {
        var event = ev || window.event;
        // var scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
        if (
          event.clientY < 0 ||
          event.clientX < 0 ||
          event.clientY > wh ||
          event.clientX > ww
        ) {
          return false;
        }
        var endx = event.clientX - startx;
        var endy = event.clientY - starty;
        positionX.value = positionX.value + endx;
        positionY.value = positionY.value + endy;
        startx = event.clientX;
        starty = event.clientY;
      };
      document.onmouseup = function () {
        cursor.value = "grab";
        document.onmousemove = null;
        document.onmouseup = null;
      };
    };
    const mouseup = () => {
      document.onmousemove = null;
    };

    const getResulte = (res) => {
      console.log("res", res);
    };

    const openChooseImage = () => {
      nextTick(() => {
        uploadBtn.value.$el.click();
      });
    };
    watch(
      () => previewVisible.value,
      (val) => {
        if (!val) {
          imageSize.value = 1; // 放大
          imageRotate.value = 0; // 旋转
          positionX.value = 0; // 移动
          positionY.value = 0;
          positionZ.value = 0;
        }
      }
    );

    return {
      previewVisible,
      previewImage,
      handleCancel,
      handlePreview,
      upLoadImage,
      remove,
      imageSize: computed(() => {
        return `scale(${imageSize.value},${imageSize.value})`;
      }),
      imageTransform: computed(() => {
        return `scale(${imageSize.value},${imageSize.value}) rotate(${imageRotate.value}deg)`;
      }),
      imageTransform3D: computed(() => {
        return `translate3d(${positionX.value}px,${positionY.value}px,${positionZ.value}px)`;
      }),
      reduceMultiple,
      addMultiple,
      rotateImage,
      mousedown,
      mouseup,
      cursor,
      beforeUpload,
      ...toRefs(pageData),
      getResulte,
      uploadBtn,
      openChooseImage,
    };
  },
  components: {
    // UploadOutlined,
    [Upload.name]: Upload,
    CloseOutlined,
    ZoomOutOutlined,
    ZoomInOutlined,
    RedoOutlined,
    PlusOutlined,
  },
});
</script>
<style>
.upload-image .color-gray {
  color: rgba(0, 0, 0, 0.4) !important;
}
.upload-image .ant-modal-content {
  background-color: rgba(0, 0, 0, 0.4) !important;
}
.upload-image .upload-image-tool {
  position: absolute;
  right: 50%;
  bottom: 20px;
  z-index: 2000;
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  transform: translateX(50%);
}
.upload-image .upload-image-tool span {
  display: block;
  padding: 10px;
  cursor: pointer;
}
.image-box {
  -moz-transition-timing-function: linear;
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  -o-transition-timing-function: linear; /* Opera */
  transition-timing-function: linear;
}
</style>
